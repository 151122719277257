import React, {useEffect, useRef, useState} from "react";
import "./RevenueCards.css";
import DownloadWallets from "../DownloadWallets";

const CardComponent = () => {
  const [revshareData, setRevshareData] = useState(null);
  const cardsContainerRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    const url = "https://suntools-be.onrender.com/revenue";
    fetch(url)
      .then(response => response.text())
      .then(backendData => {
          const data = JSON.parse(backendData);
          if (data["error"]) {
          } else {
              setRevshareData(data);
          }
      })
      .catch(error => console.error("Error loading data."));
  }, []);

  useEffect(() => {
    const cardsContainer = cardsContainerRef.current;
    const overlay = overlayRef.current;
    const cards = Array.from(cardsContainer.querySelectorAll(".card"));

    if (overlay.children.length < 6) {
      // Ensure overlay children match the number of cards
      cards.forEach((card) => {
        const overlayCard = document.createElement("div");
        overlayCard.classList.add("card");
        const cardHeight = card.getBoundingClientRect().height;

      // Set the height of the overlay card to match
      overlayCard.style.height = `${cardHeight}px`;

      // Set the width of the overlay card to match
      const cardWidth = card.getBoundingClientRect().width;
      overlayCard.style.width = `${cardWidth}px`;
      if (overlay.children.length < 6) overlay.appendChild(overlayCard);
      });
    }

    const handleMouseMove = (e) => {
      const { left, top } = cardsContainer.getBoundingClientRect();
      const x = e.clientX - left;
      const y = e.clientY - top;

      overlay.style.setProperty("--opacity", 1);
      overlay.style.setProperty("--x", `${x}px`);
      overlay.style.setProperty("--y", `${y}px`);
    };

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry, index) => {
        const { inlineSize: width, blockSize: height } = entry.contentRect;
        const overlayCard = overlay.children[index];
        if (overlayCard) {
          overlayCard.style.width = `${width}px`;
          overlayCard.style.height = `${height}px`;
        }
      });
    });

    cards.forEach((card) => observer.observe(card));
    cardsContainer.addEventListener("mousemove", handleMouseMove);

    return () => {
      cardsContainer.removeEventListener("mousemove", handleMouseMove);
      observer.disconnect();
    };
  }, []);

  const formatBigNumber = (number) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
      <div className="wrapper">
    <main className="main flow">
      <div className="main__cards cards" ref={cardsContainerRef}>
        <div className="cards__inner">
          <div className="cards__card card">
            <h2 className="card__heading">Monthly Revshare Size</h2>
            <p>The more SunTools earns, the more we give to holders</p>
            <ul role="list" className="card__bullets flow">
              <li>$0-200k : 50%</li>
              <li>$200k-400k : 55%</li>
              <li>$400k-600k : 60%</li>
              <li>$600k-800k : 65%</li>
              <li>$800k+ : 70%</li>
            </ul>
          </div>

          <div className="cards__card card">
            <h2 className="card__heading">Holder Tiers</h2>
            <p>Hold $TOOLS to participate in revshare</p>
            <ul role="list" className="card__bullets flow">
              <li>0.15% - 0.99% : 28%</li>
              <li>1% - 1.99% : 32%</li>
              <li>2%+ : 40%</li>
            </ul>
            <p>NOTE - numbers represent % of revenue shared with certain holder group</p>
          </div>
        </div>

        <h4 style={{"text-align": "center", "font-size": "20px", "margin": "10px 0 20px"}}>Revenue Generated Since Oct 4th</h4>

        <div className="cards__inner small-cards">
          <div className="cards__card card small-card">
            <h2 className="card__heading">TRENDING - {revshareData ? (formatBigNumber(revshareData.trending.total) + ' TRX') : 'Loading...'}</h2>
            {revshareData && <DownloadWallets wallets={revshareData.trending.wallets} title={'SunToolsRevenue_Trending'} />}
          </div>
          <div className="cards__card card small-card">
            <h2 className="card__heading">SNIPER BOT - {revshareData ? (formatBigNumber(revshareData.sniper) + ' TRX') : 'Loading...'} <a href="https://tronscan.org/#/address/TMgt8xgWhksmLQq2DTifKeo1oX2uVRyBFX"><p style={{'cursor': 'pointer'}}>[Fees Wallet]</p></a></h2>
          </div>
        </div>

        <div className="cards__inner small-cards">
          <div className="cards__card card small-card">
            <h2 className="card__heading">PREMIUM TOOLS - {revshareData ? (formatBigNumber(revshareData.premium.total) + ' TRX') : 'Loading...'}</h2>
            {revshareData && <DownloadWallets wallets={revshareData.premium.wallets} title={'SunToolsRevenue_Premium'} />}
          </div>
          <div className="cards__card card small-card">
            <h2 className="card__heading">SUNTOOLS ADS - {revshareData ? (formatBigNumber(revshareData.ads.total) + ' TRX') : 'Loading...'}</h2>
            {revshareData && <DownloadWallets wallets={revshareData.ads.wallets} title={'SunToolsRevenue_Ads'} />}
          </div>
        </div>

        <div className="overlay cards__inner" ref={overlayRef}></div>
      </div>
    </main>
        </div>
  );
};

export default CardComponent;
